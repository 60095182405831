/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllUserLocation,
  deleteLocation,
} from "@src/services/location.services";
import toast from "react-hot-toast";
import ToastContent from "../../views/apps/toast/toast";
import { X } from "react-feather";

export const getUserLocations = createAsyncThunk("locations/get", async () => {
  return await getAllUserLocation();
});

export const delLocation = createAsyncThunk(
  "locations/delete",
  async (id, { dispatch }) => {
    return deleteLocation(id)
      .then(() => dispatch(getUserLocations()))
      .catch((err) => {
        toast((t) => (
          <ToastContent
            t={t}
            color="danger"
            message={err.response.data.message}
            icon={<X size={12} />}
          />
        ));
      });
  }
);
