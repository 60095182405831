/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createSlice } from "@reduxjs/toolkit";
import { getAllUsers, usersAction } from "../services/user.services";

import { dispatch } from "./store";

import toast from "react-hot-toast";
import ToastContent from "../views/apps/toast/toast";
import { Check } from "react-feather";

// import axios from "axios";

export const userSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
    page: 1,
    rowPerPage: 10,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    isFectchSuccess(state) {
      state.isLoading = false;
    },
    // GET USERS
    getUsersSuccess: (state, action) => {
      state.data = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    paginationActions(state, action) {
      const { page, rowPerPage } = action.payload;
      state.page = page;
      state.rowPerPage = rowPerPage;
    },
  },
});

export const {
  startLoading,
  getUsersSuccess,
  isFectchSuccess,
  hasError,
  paginationActions,
} = userSlice.actions;

export default userSlice.reducer;

export const getUsers = (page, limit) => {
  return async () => {
    dispatch(userSlice.actions.startLoading());
    dispatch(paginationActions({ page, rowPerPage: limit }));
    try {
      const response = await getAllUsers(page, limit);
      dispatch(userSlice.actions.getUsersSuccess(response));
      dispatch(userSlice.actions.isFectchSuccess());
    } catch (error) {
      dispatch(userSlice.actions.hasError(error));
    }
  };
};

export const userAction = (body, pagiProps) => {
  return async () => {
    // dispatch(userSlice.actions.startLoading());
    try {
      const response = await usersAction(body);
      toast((t) => (
        <ToastContent
          t={t.id}
          message={response.message}
          icon={<Check size={12} />}
        />
      ));
      dispatch(getUsers(pagiProps.page, pagiProps.limit));
    } catch (error) {
      dispatch(userSlice.actions.hasError(error));
      toast((t) => <ToastContent t={t.id} message={response.message} />);
    }
  };
};
