/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Check, X } from "react-feather";
import toast from "react-hot-toast";
import {
  getAllAuction,
  recoverAllAuction,
  updateAuction,
} from "../../services/auction.services";
import ToastContent from "../../views/apps/toast/toast";

export const getAuctions = createAsyncThunk("auctions/get", async (locid) => {
  return await getAllAuction(locid);
});

export const updateAuctions = createAsyncThunk(
  "updateAuctions/post",
  async (body, { dispatch }) => {
    const eventToUpdate = {
      id: body.id,
      startDate: body.startDate,
      endDate: body.endDate,
      endTime: body.endTime,
      // startNumber: body.startNumber,
      warehouses: body.warehouses,
    };
    updateAuction(eventToUpdate)
      .then((res) => {
        dispatch(getAuctions(body.LocationID));
        toast((t) => (
          <ToastContent
            t={t}
            color="success"
            message={res.message}
            icon={<Check size={12} />}
          />
        ));
      })
      .catch((res) => {
        toast((t) => (
          <ToastContent
            t={t}
            color="danger"
            message={res.response.data.message}
            icon={<X size={12} />}
          />
        ));
      });
  }
);

export const recoverAuctions = createAsyncThunk(
  "recoverAuction/get",
  async (data, { dispatch }) => {
    recoverAllAuction(data.id)
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t}
            color="success"
            message={res.message}
            icon={<Check size={12} />}
          />
        ));
        dispatch(getAuctions(data.LocationID));
      })
      .catch((err) => {
        toast((t) => (
          <ToastContent
            t={t}
            color="danger"
            message={err.response.data.message}
            icon={<X size={12} />}
          />
        ));
      });
  }
);
