/* eslint-disable semi */
import authHttp from "./authHttp";

export const getReports = async (value) => {
  const url = `admin/reports?location=${value.location}&range=${value.range}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getAllScans = async (value) => {
  const url = `admin/reports/allscans?location=${value.location}&page=${value.page}&limit=${value.limit}`;
  const { data } = await authHttp.get(url);
  return data;
};
