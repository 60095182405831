import { createSlice } from "@reduxjs/toolkit"
import { getUserLocations, delLocation } from "./location.thunk"
const initialState = {
    allUserLocation: {
        isLoading: false,
        data: [],
        error: null
    }
}
const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getUserLocations.pending, (state) => {
            state.allUserLocation.isLoading = true
        })
        builder.addCase(getUserLocations.fulfilled, (state, action) => {
            state.allUserLocation.isLoading = false
            state.allUserLocation.data = action.payload
            state.allUserLocation.error = null
        })
        builder.addCase(getUserLocations.rejected, (state, action) => {
            state.allUserLocation.isLoading = false
            state.allUserLocation.data = []
            state.allUserLocation.error = action.error.message
        })
        builder.addCase(delLocation.pending, (state) => console.log(state))
    }
})

export default locationSlice.reducer