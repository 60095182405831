/* eslint-disable semi */
import authHttp from "./authHttp";

export const getALLProductData = async (value) => {
  const url = `admin/barcodelookup?page=${value.page}&limit=${value.limit}`;
  const { data } = await authHttp.get(url);

  return data;
};

export const editProduct = async (barcodeId, value) => {
  const url = `admin/barcodelookup/${barcodeId}`;
  const { data } = await authHttp.put(url, value);
  return data;
};

export const createProduct = async (val) => {
  const url = `admin/barcodelookup`;
  const { data } = await authHttp.post(url, val);
  return data;
};

export const getProductDetails = async (barcodeId) => {
  const url = `admin/barcodelookup/${barcodeId}`;
  const { data } = await authHttp.get(url);
  return data;
};
