/* eslint-disable comma-dangle */
/* eslint-disable semi */
// ** Redux Imports
import rootReducer from "./rootReducer";
import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";
// import { persistStore, persistReducer } from "redux-persist";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === "development") {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat(logger);
    } else {
      return getDefaultMiddleware({
        serializableCheck: false,
      });
    }
  },
});

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { store, dispatch, useSelector, useDispatch };
