/* eslint-disable comma-dangle */
/* eslint-disable semi */

import authHttp from "./authHttp";

export const getAllUsers = async (page, limit) => {
  const url = `/admin/users?page=${page}&limit=${limit}`;
  // const url = `/admin/users?page=1&limit=50`;
  const { data } = await authHttp.get(url);
  return data;
};

export const usersAction = async (body) => {
  const url = `/admin/users`;
  const { data } = await authHttp.post(url, body);
  return data;
};
