import { createAsyncThunk } from "@reduxjs/toolkit";
import { getALLProductData } from "../../services/database.service";
import { paginationActions } from "./database";

export const getALLProducts = createAsyncThunk(
  "getALLProductData/post",
  async (val, { dispatch }) => {
    dispatch(paginationActions(val));
    return await getALLProductData(val);
  }
);
