/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createSlice } from "@reduxjs/toolkit";
import { getALLProducts } from "./database.thunk";

const initialState = {
  isLoading: false,
  allProducts: [],
  page: 1,
  rowPerPage: 10,
  error: null,
};
const databaseSlice = createSlice({
  name: "database",
  initialState,
  reducers: {
    paginationActions(state, action) {
      const { page, limit } = action.payload;
      state.page = page;
      state.rowPerPage = limit;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getALLProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getALLProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allProducts = action.payload;
      state.error = null;
    });

    builder.addCase(getALLProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.allProducts = [];
    });
  },
});

export const {
  setActiveLocation,
  setActiveAuction,
  setAuctiondata,
  setIsDownload,
  paginationActions,
} = databaseSlice.actions;

export default databaseSlice.reducer;
