/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createSlice } from "@reduxjs/toolkit";
import { getAuctions, recoverAuctions, updateAuctions } from "./auction.thunk";
import { setAuctionstoEvent } from "./utils";

const initialState = {
  isLoading: false,
  data: [],
  updateAuctionData: {},
  selectedEvent: {},
  recoverdata: {},
  error: null,
};
const auctionSlice = createSlice({
  name: "auction",
  initialState,
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAuctions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAuctions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = setAuctionstoEvent(action.payload);
      state.error = null;
    });
    builder.addCase(getAuctions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.data = [];
    });
    builder.addCase(updateAuctions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updateAuctionData = action.payload;
      state.error = null;
    });
    builder.addCase(recoverAuctions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recoverdata = action.payload;
      state.error = null;
    });
  },
});
export const { selectEvent } = auctionSlice.actions;

export default auctionSlice.reducer;
