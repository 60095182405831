import authHttp from "./authHttp"

export const addLocation = async (payload) => {
    const url = '/admin/location'
    const { data } = await authHttp.post(url, payload)
    return data
}

export const getLocation = async (location) => {
    const url = '/admin/location'
    const { data: { data } } = await authHttp.get(url, { params: { location } })
    return data
}

export const getAllUserLocation = async () => {
    const url = "/user/location"
    const { data: { data } } = await authHttp.get(url)
    return data
}

export const deleteLocation = async (id) => {
    const url = `/admin/location/${id}`
    const { data } = await authHttp.del(url)
    return data
}

export const editLocation = async (id, body) => {
    const url = `/admin/location/${id}`
    const { data } = await authHttp.put(url, body)
    return data
}