/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReports, getAllScans } from "@src/services/reports.services";

export const getReport = createAsyncThunk("reports/get", async (value) => {
  return await getReports(value);
});

export const getAllReport = createAsyncThunk(
  "allreports/get",
  async (value) => {
    return await getAllScans(value);
  }
);
