/* eslint-disable semi */

/* eslint-disable comma-dangle */
export const setAuctionstoEvent = (data) => {
  return data.map((auc) => (
    { id: auc.id,
    title: auc.auctionType,
    start: new Date(`${auc.startDate} ${auc.startTime}`),
    end: new Date(`${auc.endDate} ${auc.endTime}`),
    allDay: false,
    isRecover: auc.isRecover,
    startNumber: auc.startNumber,
    status: auc.status,
    _count: auc._count,
    extendedProps: {
      startTime: new Date(`${auc.startDate} ${auc.startTime}`),
      endTime: new Date(`${auc.endDate} ${auc.endTime}`),
      auctionStartNo: auc.auctionStartNo
    },
  }));
};
