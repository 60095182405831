/* eslint-disable semi */
import React from "react";
import Avatar from "@components/avatar";
import { X } from "react-feather";
import toast from "react-hot-toast";

const ToastContent = (props) => {
  const { message, t, icon, color } = props;
  return (
    <div className="d-flex justify-content-between alifn-items-center">
      <div className="d-flex ">
        <div>
          <Avatar size="sm" color={color ? color : "success"} icon={icon} />
        </div>
        <div className="d-flex">
          <span className="mx-1 fw-bolder text-capitalize" color="#2d2d2d">
            {message}
          </span>
          <X
            size={18}
            className="cursor-pointer me-0 float-right"
            onClick={() => toast.dismiss(t.id)}
          />
        </div>
      </div>
    </div>
  );
};

export default ToastContent;
