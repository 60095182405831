/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createSlice } from "@reduxjs/toolkit";
import { getAllReport, getReport } from "./report.thunk";

const initialState = {
  isLoading: false,
  data: [],
  allScanReports: [],
  page: 1,
  rowPerPage: 10,
  error: null,
  activeLocation: [],
  activeRange: [],
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setActiveLocation: (state, action) => {
      state.activeLocation = action.payload;
    },
    setActiveRange: (state, action) => {
      state.activeRange = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReport.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getReport.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.data = [];
    });
    // allScanReports
    builder.addCase(getAllReport.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllReport.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allScanReports = action.payload;
      state.error = null;
    });
    builder.addCase(getAllReport.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.allScanReports = [];
    });
  },
});

export const { setActiveLocation, setActiveRange } = reportSlice.actions;

export default reportSlice.reducer;
